<template>
    <div class="user-view-container">
        <div class="title-container">
            <div class="back-icon" @click="closeView()"><i class="el-icon-back"></i><span class="view-name">更改昵称</span></div>
            <div class="tool-box">
                <el-button class="btn-ok" type="primary" size="mini" @click="saveUsername()">保存</el-button>
            </div>
        </div>
        <div class="edit-container">
            <el-input placeholder="输入昵称" v-model="username" >
            </el-input>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserNameEditView',
    props: {
        username: String
    },
    data() {
        return {
            // username: ""
        }
    },
    methods: {
        closeView() {
            this.$emit("closeView");
            this.$router.go(-1);
        },
        saveUsername() {
            this.$emit("closeView");
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
    .user-view-container {
        position: relative;
        height: 100%;
        overflow-y: auto;

        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #f6f6f6;
        z-index: 3;
    }
    .title-container {
        height: 12vmin;

        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }
    .title-container .back-icon {
        font-size: 6vmin;
        padding: 0 3vmin;

        display: flex;
        align-items: center;
    }
    .title-container .view-name {
        font-size: 4vmin;
        margin-left: 3vmin;
    }
    .title-container .back-icon:active {
        background-color: #eaeaea;
    }
    .title-container .tool-box {
        padding: 0 5vmin;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .edit-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 12vmin;
        bottom: 0;

        font-size: 4vmin;
        text-align: left;
        overflow-y: auto;
        padding-top: 2vmin;
    }
</style>